import { AxiosError } from "axios";
import { useState, useRef } from "react";
import toast from "react-hot-toast";

import {
  ErrorHttpResponse,
  HttpConfig,
  SuccessHttpResponse,
} from "../models/https";
import useHttps from "./useHttps";

export interface VerifyResetResponse {
  b64uid: string;
  b64token: string;
}

const useVerifyResetToken = () => {
  const request = useHttps();
  const [loading, setLoading] = useState(false);

  // Ref to store processed tokens to prevent duplicate calls
  const processedTokens = useRef<Set<string>>(new Set());

  const onVerifyError = (err: AxiosError<ErrorHttpResponse>) => {
    console.log("onVerifyResetTokenError");
    toast.error(
      err?.response?.data?.message ?? "Invalid or expired reset link."
    );
  };

  const onVerifySuccess = ({
    data,
  }: SuccessHttpResponse<VerifyResetResponse>) => {
    toast.success("Password reset link successfully verified!");
    localStorage.setItem("resetData", JSON.stringify(data));
  };

  const verifyResetToken = (b64uid: string, b64token: string) => {
    const tokenKey = `${b64uid}-${b64token}`;

    // Check if the token has already been processed
    if (processedTokens.current.has(tokenKey)) {
      console.log("Token already verified or in progress.");
      return;
    }

    // Mark token as being processed
    processedTokens.current.add(tokenKey);

    const url: HttpConfig = {
      url: `auth-service/users/reset-password/${b64uid}/${b64token}`,
      method: "get",
    };

    request(
      url,
      (response) => {
        onVerifySuccess(response);
        processedTokens.current.add(tokenKey); // Ensure it's marked as completed
      },
      setLoading,
      (error) => {
        onVerifyError(error);
        processedTokens.current.delete(tokenKey); // Allow retry on error
      }
    );
  };

  return {
    loading,
    verifyResetToken,
  };
};

export default useVerifyResetToken;
