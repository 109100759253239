import { AxiosError } from "axios";
import { useState, useRef } from "react";
import toast from "react-hot-toast";

import {
  ErrorHttpResponse,
  HttpConfig,
  SuccessHttpResponse,
} from "../models/https";
import useHttps from "./useHttps";

// Type definition for the request payload
export interface ResetPasswordData {
  password: string;
  b64uid: string;
  b64token: string;
}

const useUserResetPassword = () => {
  const request = useHttps();
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  // Use a ref to track processed passwords to prevent duplicate requests
  const processedPayloads = useRef<Set<string>>(new Set());

  const onResetPasswordError = (err: AxiosError<ErrorHttpResponse>) => {
    console.log("onResetPasswordError");
    toast.error(err?.response?.data?.message ?? "Failed to reset password.");
  };

  const onResetUserPassword = (_: SuccessHttpResponse<any>) => {
    toast.success("Password reset successfully!");
    localStorage.removeItem("resetData"); // Clear reset data from local storage
    setCompleted(true);
  };

  const resetUserPassword = (password: string) => {
    // Retrieve `b64uid` and `b64token` from local storage
    const resetData = localStorage.getItem("resetData");
    if (!resetData) {
      toast.error("Reset data is missing. Please try again.");
      return;
    }

    const { b64uid, b64token } = JSON.parse(resetData);

    // Construct the payload
    const payload: ResetPasswordData = { password, b64uid, b64token };
    const payloadKey = JSON.stringify(payload);

    // Check if the payload has already been processed
    if (processedPayloads.current.has(payloadKey)) {
      console.log("Password reset request already processed.");
      return;
    }

    // Mark the payload as being processed
    processedPayloads.current.add(payloadKey);

    const url: HttpConfig = {
      url: "auth-service/users/reset-password",
      method: "patch",
      data: payload,
    };

    // Make the request
    request(
      url,
      (response) => {
        onResetUserPassword(response);
        processedPayloads.current.add(payloadKey); // Mark as completed
      },
      setLoading,
      (error) => {
        onResetPasswordError(error);
        processedPayloads.current.delete(payloadKey); // Allow retry on error
      }
    );
  };

  return {
    loading,
    resetUserPassword,
    completed,
  };
};

export default useUserResetPassword;
